import React from "react"
import Mascot from "./mascot"
import "../styles/userSelections.css"

const UserSelections = ({matchups, userVotes}) => {

  return (
    <div className="user-selections">
      <h2>
        {Object.values(userVotes).length !== matchups.length ? "Your Selection (So Far)" : "Your Championship Selection"}
      </h2>
      <div className="user-selections-inner">
        {matchups.map((matchup, i) => {
          return (
            <div className="single-selection" key={i}>
              { (() => {
                if (userVotes[i + 1] === "A") {
                  return (
                    <div>
                      <Mascot
                        size="mini"
                        className="mini-image"
                        imagePath={matchup.contenderA.image}
                        alt={"Photo of " + matchup.contenderA.mascot}
                      />
                      <div className="mascot-name">{matchup.contenderA.mascot}</div>
                    </div>
                  )
                } else if (userVotes[i + 1] === "B") {
                  return (
                    <div>
                      <Mascot
                        size="mini"
                        className="mini-image"
                        imagePath={matchup.contenderB.image}
                        alt={"Photo of " + matchup.contenderB.mascot}
                      />
                      <div className="mascot-name">{matchup.contenderB.mascot}</div>
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <h3 className="unknown">?</h3>
                      <em>(No selection made)</em>
                    </div>
                  )
                }
              })()}
            </div>
          )
        })}

      </div>
    </div>
  )
}

export default UserSelections