import React, {useState} from 'react'
import { Link } from 'gatsby';
import fetch from 'node-fetch';

import matchups from "../config/r5Matchups"

import Firebase from "../services/firebase"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Logo from "../components/logo"
import VotingPrompt from '../components/votingPrompt'
import UserSelections from "../components/userSelections"

import '../styles/bracket.css';

const IndexPage = () => {

  const [matchupNumber, setMatchupNumber] = useState(0)
  const [userHasVoted, setUserHasVoted] = useState(false)
  const [userVotes, setVotes] = useState({})
  const [contentVisible, setVisible] = useState(true)
  const [ipAddress, setIpAddress] = useState("")

  async function fetchIp() {
    const regex = /^(?:ip)=(.*)$/gm;
    let response = await fetch("https://www.cloudflare.com/cdn-cgi/trace", {mode: "cors"})
    let text = await response.text()
    let match;
    let ip = "";
    match = regex.exec(text);
    while (match != null) {
      ip = ip.concat(match[1] + "\n");
      match = regex.exec(text);
    }
    return ip;
  }

  const getPreviousVotes = (ip) => {
    Firebase.auth().signInAnonymously()
      .then(() => {
        Firebase.store.collection("votesr5").doc(ip).get().then(function (doc) {
          if (doc.exists) {
            let storedVotes = doc.data()
            if (typeof storedVotes === "object" && JSON.stringify(storedVotes) !== JSON.stringify(userVotes)) {
              setVotes(storedVotes)
              if (userHasVoted === false)
                setUserHasVoted(true)
            }
          }
        }).catch(function (error) {
          console.error("Error getting previous votes:", error)
        })
      })
      .catch((error) => {
        console.log("Error authenticating with Firebase: ", error.code)
        console.log(error.message)
      })

  }

  fetchIp().then((ip) => {
    if (ip !== ipAddress ) {
      setIpAddress(ip)
      getPreviousVotes(ip);
    }
  }).catch(function (error) {
    console.error("Error fetching IP:", error)
  });

  const nextMatchup = () => {
    setVisible(false);
    setTimeout(() => {
      setMatchupNumber(matchupNumber + 1)
      setVisible(true)
      document.querySelector(".app-container-inner").scroll({
        top: 0,
        behavior: "smooth"
      })
    }, 120)
  }

  const prevMatchup = () => {
    setVisible(false);
    setTimeout(() => {
      setMatchupNumber(matchupNumber - 1)
      setVisible(true)
    }, 250)
  }

  const castVote = (matchup, vote) => {

    let votes = Object.assign({}, userVotes)
    votes[matchup] = vote
    setVotes(votes)

    Firebase.store.collection("votesr5").doc(ipAddress).set(Object.assign({},votes)).then(() => {

    }).catch(function(error) {
      console.error("Error writing document: ", error);
    });
  }

  const getIntro = () => {
    if (userHasVoted === true) {
      return Object.values(userVotes).length !== matchups.length ? (
        <>
          <h1>Finish What You Started</h1>
          <p>It looks like you began to vote, but you still have some mascots left to consider.</p>
          <button onClick={nextMatchup}>
            Finish Voting
          </button>
          <UserSelections matchups={matchups} userVotes={userVotes} />
        </>
      ) :
      <>
        <h1>Voting Complete</h1>
        <p>The champion will be announced at the January 5th huddle.</p>
        <button onClick={nextMatchup}>
          Adjust my Votes
        </button>
        <UserSelections matchups={matchups} userVotes={userVotes} />
      </>
    } else if  (userHasVoted === false ) {
      return (
        <>
          <h1>CHAMPIONSHIP</h1>
          <button onClick={nextMatchup}>
            Make Your Final Selection
          </button>
        </>
      )
    } else  {
      return (
        <>
          <h1>Loading...</h1>
          <p>Checking to see if you've already voted.</p>
        </>
      )
    }
  }

  return (
    <Layout>
      <SEO title="Home"/>

      <div className="form-container">
        <div className="detail-line">The time has come</div>
        {
          matchupNumber <= 0 ?

            <div className="form-container-inner">
              <Logo maxWidth="300px" maxHeight="170px" className="main-logo"/>
              {getIntro()}
              <div className="previous-matchup-container">
                <Link
                  className="previous-matchup"
                  to="/bracket"
                >
                  &rarr; View Full Bracket
                </Link>
              </div>
            </div>
            :
            <div className="form-container-inner">
              <VotingPrompt
                userVotes={userVotes}
                matchupNumber={matchupNumber}
                contentVisible={contentVisible}
                castVote={castVote}
              />

              {typeof userVotes[matchupNumber] !== "undefined" ?
                <div className="next-matchup-container">
                  {matchupNumber < matchups.length ?
                    <button onClick={nextMatchup}>Next Matchup</button> :
                    <button onClick={() => {
                      setMatchupNumber(0);
                      setUserHasVoted(true)
                    }}>That's All, Folks!</button>
                  }
                </div>
                :
                <div className="voting-instructions">
                  Vote for your favorite.
                </div>
              }

              {matchupNumber > 1 ?
                <div className="previous-matchup-container">
                  <a
                    className="previous-matchup"
                    onClick={prevMatchup}
                  >
                    &larr; Previous Matchup
                  </a>
                </div>
                :
                null
              }

            </div>
        }
      </div>

    </Layout>
  );
}

export default IndexPage;
